@import './variables';
@import './mixins';

.button-dark {
  height: 100%;
  object-fit: contain;

  @include fill('circle', $blue-dark);
  @include fill('path', #FFFFFF);

  &:active {
    cursor: pointer;
    @include fill('circle', $blue-light);
    @include fill('path', #FFFFFF);
  }
}

.button-light {
  height: 100%;
  object-fit: cover;

  @include fill('circle', #FFFFFF);
  @include fill('path', $blue-dark);

  &:active {
    cursor: pointer;
    @include fill('circle', $blue-light);
    @include fill('path', #FFFFFF);
  }
}

.button-light-stroke {
  height: 100%;
  object-fit: contain;

  @include fill('path', $blue-dark);

  &:active {
    cursor: pointer;
    @include fill('path', $blue-light);
  }
}


@media (hover: hover) and (pointer: fine) {
  .button-dark {
    &:hover {
      cursor: pointer;
      @include fill('circle', #FFFFFF);
      @include fill('path, text', $blue-dark);
    }

    * {
      transition: $transition-delay-fast ease-out;
    }
  }

  .button-light {
    &:hover {
      cursor: pointer;
      @include fill('circle', $blue-dark); // $blue-medium
      @include fill('path', #FFFFFF);
    }

    * {
      transition: $transition-delay-fast ease-out;
    }
  }

  .button-light-stroke {
    &:hover {
      cursor: pointer;
      @include fill('path', $blue-light);
    }
  }
}