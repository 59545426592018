@import 'src/styles/shared';

$circle-width: 40px;
$circle-radius: $circle-width / 2;
$dark-color: #ed5d26;
$light-color: #ffffff;

.annotation-container {
  pointer-events: none;
  user-select: none;

  .annotation.dangerClick {
    pointer-events: none;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px); // TODO keep this?

    width: 100vw !important;
    min-width: initial !important;
    max-width: px-to-rem(400px);

    border-radius: 1.5rem !important; // round
    border: none !important;
    //box-shadow: none !important;
    box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(black, 0.2) !important;

    transform-origin: center center;
    transform: translate(-50%, -50%);

    &.visible {
      opacity: 1;
    }

    .annotation-tooltip {
      color: $dark-color;
      padding: 1.25rem;
      display: inline-block;

      font-size: 1.15rem;
      font-weight: 700;

      z-index: 1;

      img {
        width: 12rem;
        height: 12rem;
      }

      &.tablet {
        font-size: 1rem;
        padding: 1rem;
      }

      &.mobile {
        font-size: 0.85rem;
        padding: .75rem;
      }
    }
  }

  .annotation-circle.dangerClick {
    width: px-to-rem($circle-width);
    height: px-to-rem($circle-width);
    position: absolute;
    transform: translate(-50%, -50%);

    animation: sinus 1s alternate infinite ease-in-out;
    pointer-events: visibleFill;

    //border: 1px solid red;
    box-sizing: content-box;
    z-index: 2;

    circle#backCircle {
      fill: $dark-color;
      cx: px-to-rem($circle-radius) !important;
      cy: px-to-rem($circle-radius);
      r: px-to-rem(18px);
    }

    circle#frontCircle {
      //fill: transparent;
      fill: $light-color;
      cx: px-to-rem($circle-radius) !important;
      cy: px-to-rem($circle-radius);
      r: px-to-rem(14px);

      transition: all 0.3s ease;
    }

    text {
      text-anchor: middle;
      fill: $dark-color;
      font-size: px-to-rem(24px);
      font-weight: 600;
      transform-origin: px-to-rem($circle-radius);

      transition: all 0.3s ease;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .annotation-container {
    .annotation-circle.dangerClick {
      &:hover {
        animation: none;
        transform: translate(-50%, -50%) scale(1.25);
        cursor: pointer;
      }
    }
  }
}

@keyframes sinus {
  // jerky transitions in firefox while rendering webgl content at the same time
  0% {
    transform: translate(-50%, -50%) scale(1.25);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}