@import './variables';
@import './mixins';

.label-error {
  border: solid 1px red;
  background: #FF000010;
  color: red;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 28.75rem;
}