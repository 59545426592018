@import 'src/styles/shared';

.main-container {
  //@include debug-child-div-random-background(10, 0.15); // TODO REMOVE DEBUG

  z-index: 100; // below Header anv above Canvas
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: center / cover no-repeat url('../../assets/images/mainBackground.jpg');
}

h2 {
  color: #5895CC;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: .75rem;
}