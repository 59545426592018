@import './variables';
@import './functions';

@mixin header-background($account-type) {
  $color: get-main-color($account-type);
  background: transparent linear-gradient(270deg, rgba($color, 0), rgba($color, 1));
}

@mixin solid-background($account-type) {
  $color: get-main-color($account-type);
  background-color: $color;
}

@mixin debug-child-div-random-background($number, $alpha: 0.33) {
  @for $i from 1 to $number {
    div:nth-child(#{$i}) {
      @include debug-random-background($alpha);
      //background: hsla(random(360), 100%, 50%, $alpha);
    }
  }
}

@mixin debug-random-background($alpha: 0.33) {
  background: hsla(random(360), 100%, 50%, $alpha);
}

@mixin fill($selectors, $color) {
  #{$selectors} {
    fill: $color;
  }
}
