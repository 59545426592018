@import 'src/styles/shared';

.spinner {
  //animation: spinner-rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  //top: 50%;
  bottom: 25%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $blue-light;
    stroke-linecap: round;
    animation: spinner-rotate 2s linear infinite, spinner-dash 1.5s ease-in-out infinite;
    transform-origin: 25px;
  }

  & .text {
    animation: spinner-rotate 2s linear infinite reverse;
    text-anchor: middle;
    fill: $blue-light;
    font-size: 16px;
    font-weight: 700;
    transform: scale(1);
    transform-origin: 25px;

    transition: all 0.3s ease;
  }
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
