@import 'src/styles/shared';

.carrousel {
  //pointer-events: auto;
  // position: absolute;
  // bottom: px-to-rem(160px);

  width: 100%;
  height: px-to-rem(300px);
  // overflow: hidden;

  padding: px-to-rem(33px) px-to-rem(40px) px-to-rem(20px) px-to-rem(40px);
  // padding: px-to-rem(33px) px-to-rem(40px) 0 px-to-rem(40px);

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.carrousel-item {
  flex: 1;
  // pointer-events: auto; // TODO DEBUG REMOVE PROD
  // height: 100%;
  // margin: 0 px-to-rem(32px); // if commented caption can expand horizontally

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  img {
    // item
    pointer-events: auto;
    height: px-to-rem(200px); // TODO hack to prevent scrollbar... otherwise image + caption is higher than container...
    max-width: 100%;
    // max-height: 100%;
  }

  &.modal {
    flex: 1; 

    width: 90%;
    height: 100%;
    
    img {
      object-fit: contain;

      // TODO FIX INCORRECT IMAGE SCALING !!!!
      
      width: 95%;
      height: 95%;
    }
  }

  &.opened {
    img {
      cursor: initial !important;
    }
  }
}

.carrousel-item-caption {
  padding-top: px-to-rem(22px);
  font-size: px-to-rem(22px);
  font-style: italic;
}

.carrousel-item-caption-small {
  margin: 0 px-to-rem(-20px);
  padding-top: px-to-rem(12px);
  font-size: px-to-rem(20px);
  text-align: center;
  line-height: px-to-rem(22px);
  font-style: italic;
}

.carrousel-arrow {
  pointer-events: auto;
  width: px-to-rem(48px);
  height: px-to-rem(48px);

  img {
    // arrow
    width: 100%;
    height: 100%;
  }
}

.carrousel-modal {
  z-index: 950;
  //pointer-events: auto;

  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px) brightness(1.15);
  box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(black, 0.2) !important;

  position: fixed;
  margin: auto; // center div
  // width: px-to-rem(1392px);
  // height: px-to-rem(944px);
  width: 72.5%;
  height: 85%;
  border-radius: px-to-rem(30px);

  padding: px-to-rem(48px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  //transition: $transition-delay ease;

  .carrousel-item-modal {}
}

.carrousel-modal-close {
  pointer-events: auto;
  width: px-to-rem(48px);
  height: px-to-rem(48px);

  position: absolute;
  flex: none;

  top: px-to-rem(48px);
  right: px-to-rem(48px);

  img {
    // arrow
    width: 100%;
    height: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .carrousel-arrow:hover {
    cursor: pointer;
  }

  .carrousel-item {
    img {
      cursor: pointer;
    }
  }

  .carrousel-modal-close {
    img {
      cursor: pointer;
    }
  }
}