@import 'src/styles/shared';

$circle-width: 40px;
$circle-radius: $circle-width / 2;
$dark-color: #ed5d26;
$light-color: #ffffff;

.annotation-circle.orangeFixed {
  pointer-events: auto;
  user-select: none;
  width: px-to-rem($circle-width);
  height: px-to-rem($circle-width);
  position: absolute;
  //transform: translate(-50%, -50%) scale(1); // overriden by animation
  box-sizing: content-box;
  z-index: 2;

  &.animated {
    animation: sinus 1s alternate infinite ease-in-out;
  }

  circle#backCircle {
    fill: $dark-color;
    cx: $circle-radius !important;
    cy: $circle-radius;
    r: 16px;
    fill: white;
    opacity: 0.75;

    &.opaque {
      opacity: 1 !important;
    }
  }

  path#contour {
    fill: none;
    stroke: $dark-color;
    stroke-linecap: round;
    stroke-width: 4px;
  }

  text#text {
    font-size: 22px;
    fill: $dark-color;
    font-weight: 600;
    text-transform: uppercase;
    //transform-origin: px-to-rem($circle-radius);
  }
}

.fixedAnnotationsContainer {
  //background-color: rgba(255, 0, 0, 0.8);
  pointer-events: none;

  position: fixed;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 0 px-to-rem(100px) px-to-rem(150px);

  .annotation.orangeFixed {
    //background-color: rgba(0, 255, 0, 0.8); // TODO REMOVE DEBUG

    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-around;

    margin-top: px-to-rem(28px);

    &:first-child {
      margin-top: 0;
    }

    .blurred-background {
      pointer-events: auto; // otherwise clicking exits state

      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(2px); // TODO keep this?
      border-radius: px-to-rem(24px);
      z-index: 1;

      display: inline-block;

    }

    .description {
      margin-left: px-to-rem($circle-radius);
      max-width: px-to-rem(500px);

      padding: px-to-rem(20px) px-to-rem(20px) px-to-rem(20px) px-to-rem(40px);
      white-space: normal;
      word-wrap: break-word;

      color: $blue-dark; //$dark-color; // changed from orange to blue to respect mock-up
      font-size: 1.15rem;
      font-weight: 700;

      box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(black, 0.2) !important;

      &.tablet {
        font-size: 1rem;
        padding: px-to-rem(16px) px-to-rem(16px) px-to-rem(16px) px-to-rem(32px);
      }

      &.mobile {
        font-size: 0.85rem;
        padding: px-to-rem(14px) px-to-rem(14px) px-to-rem(14px) px-to-rem(28px);
      }
    }

    .title {
      color: $blue-dark;
      font-size: 1.6rem;
      font-weight: 700;

      margin-left: px-to-rem(-84px);
      padding: px-to-rem(20px) px-to-rem(28px);

      &.tablet {
        font-size: 1.45rem;
        padding: px-to-rem(16px) px-to-rem(22px);
      }

      &.mobile {
        font-size: 1.3rem;
        padding: px-to-rem(14px) px-to-rem(20px);
      }
    }
  }
}

@keyframes sinus {
  0% {
    transform: translate(-50%, -50%) scale(1.25);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}