@import 'src/styles/shared';

.navigation {
  pointer-events: none;

  z-index: 900; // above Main (100) & Annotations (500) & SidePanel (800)
  position: absolute;

  &.bottom-right {
    bottom: px-to-rem(60px);
    right: px-to-rem(656px / 2); // center of side panel
    transform: translateX(50%); // centering div on itself

    &.background {
      background-color: rgba(229, 229, 229, .5);
      backdrop-filter: blur(30px) brightness(1.15);
    }
  }

  &.top-right {
    top: px-to-rem(260px);
    // right: px-to-rem(360px); // top right corner of fullpage panel
    //transform: translateX(50%); // centering div on itself

    // variable width: fix small width title/navigatin overlapping
    justify-content: flex-end;
    width: px-to-rem(1200px);
    max-width: 95%;
    padding-right: 0 !important;
  }

  //width: px-to-rem(656px); // auto
  height: px-to-rem(80px);
  border-radius: px-to-rem(40px);
  padding: px-to-rem(10px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .navigation-button {
    z-index: 901;
    background-color: white;
    pointer-events: visible;
    transition: background-color $transition-delay ease-out;

    color: $blue-dark;
    font-size: px-to-rem(22px);
    font-weight: 700;
    text-transform: uppercase;

    border-radius: px-to-rem(30px);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.main {
      width: px-to-rem(60px);
      height: px-to-rem(60px);

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    &.previous, &.next {
      height: px-to-rem(60px);
      padding: px-to-rem(20px);

      & > img {
        max-height: px-to-rem(22px);
      }
    }

    &.previous {
      width: px-to-rem(186px);
    }

    &.next {
      width: px-to-rem(157px);
    }

    &.disabled {
      pointer-events: none !important;

      & > * {
        opacity: 0.4;
      }
    }
  }

  .navigation-button-circle {
    z-index: 901;
    pointer-events: visible;
    //transition: background-color $transition-delay ease-out;

    border-radius: px-to-rem(24px);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: px-to-rem(48px);
    height: px-to-rem(48px);

    position: fixed;

    & > img {
      width: 100%;
      height: 100%;
    }

    &.close {
      top: px-to-rem(80px);
      right: px-to-rem(100px);
      width: px-to-rem(72px);
      height: px-to-rem(72px);
    }

    &.previous {
      top: 50%;
      transform: translateY(-50%);
      left: px-to-rem(160px);
    }

    &.next {
      top: 50%;
      transform: translateY(-50%);
      right: px-to-rem(160px);
    }

    &.disabled {
      pointer-events: none !important;

      & > * {
        opacity: 0.4;
      }
    }
  }

  .navigation-button-divider {

    z-index: 900; // display below buttons
    margin: 0 -5px; // extend below buttons
    width: px-to-rem(20px + 5px); // add margin to width
    height: px-to-rem(8px);
    background-color: $orange;
  }
}

@media (hover: hover) and (pointer: fine) {
  .navigation {
    .navigation-button:hover {
      cursor: pointer;
      background-color: $orange !important;
      color: white;

      & > img {
        filter: saturate(0) brightness(10);
      }
    }

    .navigation-button-circle:hover {
      cursor: pointer;
      //background-color: $orange !important;
      //transform: scale(1.25);

      //& > img {
      //  filter: saturate(0) brightness(10);
      //}
    }
  }
}